@charset "utf-8";
/*.GeneralProductsContainer{

}*/
.GeneralProductsContainer .GeneralProductsHeadersContainer{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
}
.GeneralProductsContainer .GeneralProductsHeadersContainer .GeneralProductsSuperTitle,
.GeneralProductsContainer .GeneralProductsHeadersContainer .GeneralProductsScrollListContainer{
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: bottom;
}
.GeneralProductsContainer .GeneralProductsHeadersContainer .GeneralProductsSuperTitle{
    color: #153768;
}
.GeneralProductsContainer .GeneralProductsHeadersContainer .GeneralProductsScrollListContainer{
    text-align: right;
}
.GeneralProductsContainer .GeneralProductsHeadersContainer .GeneralProductsScrollListContainer button{
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #CCCCCC;
    color: #153768;
    background-color: transparent;
    cursor: pointer;
    transition: .3s;
    text-align: center;
}
.GeneralProductsContainer .GeneralProductsHeadersContainer .GeneralProductsScrollListContainer button:hover{
    color: #ffffff;
    background-color: #153768;
}
.GeneralProductsContainer .GeneralProductsHeadersContainer .GeneralProductsSeparationLine{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
}
/*------------------------------------------*/
/*------------------------------------------*/
/*------------------------------------------*/
/*------------------------------------------*/
.GeneralProductsContainer .GeneralProductsList::-webkit-scrollbar-track
{
    box-shadow: inset 0 0 1px transparent;
    background-color: transparent;
}

.GeneralProductsContainer .GeneralProductsList::-webkit-scrollbar
{
    width: 1px;
    height: 0px;
    background-color:transparent;
}

.GeneralProductsContainer .GeneralProductsList::-webkit-scrollbar-thumb
{
    background-color: transparent;
}
.GeneralProductsContainer .GeneralProductsList{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    list-style: none;
    overflow: auto;
    white-space: nowrap;
    text-align: left;
}
.GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem{
    margin: 0;
    padding: 0;
    display: inline-block;
    background-color: transparent;
    cursor: pointer;
    transition: .3s;
}
.GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem:hover .GeneralProductsListItem_ImageContainer,
.GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem:focus .GeneralProductsListItem_ImageContainer{
    background-color: transparent;
}
.GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem:hover .GeneralProductsListItem_TextContainer .GeneralProductsListItem_Name,
.GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem:focus .GeneralProductsListItem_TextContainer .GeneralProductsListItem_Name{
    color: #00C7D6;
}
.GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_ImageContainer{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    background-color: #F7F8FA;
    border: 3px solid #F7F8FA;
    transition: .3s;
}
.GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_ImageContainer .GeneralProductsListItem_TransparentTiket{
    position: relative;
    top: 15px;
    left: 20px;
    background-color: transparent;
    color: transparent;
    border: none;
}
.GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_ImageContainer .GeneralProductsListItem_NewMesage{
    position: relative;
    top: 15px;
    left: 20px;
    background-color: #00C7D6;
    color: #FFFFFF;
    border: none;
    z-index: 80;
}
.GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_ImageContainer .GeneralProductsListItem_DiscountMesage{
    position: relative;
    top: 15px;
    background-color: #C41644;
    color: #FFFFFF;
    border: none;
    z-index: 80;
}

.GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_ImageContainer .GeneralProductsListItem_Image{
    display: block;
    margin: 0;
    padding: 0;
    position: relative;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -60%);
}
.GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_TextContainer{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    background-color: transparent;
}
.GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_TextContainer .GeneralProductsListItem_Name{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    color: #000000;
    transition: .3s;
}
.GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_TextContainer .GeneralProductsListItem_SubName{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    color: #666666;
}
.GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_TextContainer .GeneralProductsListItem_PriceContainer{
   margin: 0;
   padding: 0;
   display: block;
   width: 100%;
}
.GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_TextContainer p{
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
 }
 .GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_TextContainer .GeneralProductsListItem_DiscountPrice{
    text-decoration: line-through;
    color: #CCCCCC;
 }
 .GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_TextContainer .GeneralProductsListItem_Price{
    color: #00C7D6;
 }
 
@media screen and (min-width: 701px) {
    .GeneralProductsContainer{
        margin: 6vh auto;
    }
    .GeneralProductsContainer .GeneralProductsHeadersContainer{
        margin-bottom: 0 0 2vh 0;
    }
    /*.GeneralProductsContainer .GeneralProductsHeadersContainer .GeneralProductsSuperTitle,
    .GeneralProductsContainer .GeneralProductsHeadersContainer .GeneralProductsScrollListContainer{
       
    }*/
    .GeneralProductsContainer .GeneralProductsHeadersContainer .GeneralProductsSuperTitle{
        width: 70%;
        margin-right: 3%;
        font-size: 1.7em;
    }
    .GeneralProductsContainer .GeneralProductsHeadersContainer .GeneralProductsScrollListContainer{
        width: 25%;
    }
    .GeneralProductsContainer .GeneralProductsHeadersContainer .GeneralProductsScrollListContainer button{
        border-radius: 50%;
        padding: 5px 10px;
        font-size: 15px;
        margin: 0 5px;
    }
    .GeneralProductsContainer .GeneralProductsHeadersContainer .GeneralProductsSeparationLine{
        margin: 2vh 0 4vh 0;
    }
    /*------------------------------------------*/
    /*------------------------------------------*/
    /*------------------------------------------*/
    /*------------------------------------------*/
    .GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem{
        margin: 1vh 60px 1vh 0;
        width: 350px;
    }
    .GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_ImageContainer{
        height: 40vh;
    }
    .GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_ImageContainer .GeneralProductsListItem_NewMesage{
        padding: 10px 25px;
        font-size: 1em;
    }
    .GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_ImageContainer .GeneralProductsListItem_DiscountMesage{
        left: 215px;
        padding: 10px 25px;
        font-size: 1em;
    }
    .GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_ImageContainer .GeneralProductsListItem_Image{
        height: 35vh;
        max-width: 100% !important;
    }
    .GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_TextContainer{
       margin-top: 1vh; 
    }
    .GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_TextContainer .GeneralProductsListItem_Name{
        font-size: 1.4em;
        margin-bottom: 1px;
    }
    .GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_TextContainer .GeneralProductsListItem_SubName{
        font-size: 1em;
    }
    .GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_TextContainer .GeneralProductsListItem_PriceContainer{
        margin-top: 3px;
    }
    .GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_TextContainer .GeneralProductsListItem_DiscountPrice{
        margin-right: 10px;
        font-size: 1.2em;
    }
    .GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_TextContainer .GeneralProductsListItem_Price{
        font-size: 1.4em;
    }
}
@media screen and (max-width: 700px) {
	.GeneralProductsContainer{
        margin: 6vh auto;
    }
    .GeneralProductsContainer .GeneralProductsHeadersContainer{
        margin-bottom: 0 0 2vh 0;
    }
    /*.GeneralProductsContainer .GeneralProductsHeadersContainer .GeneralProductsSuperTitle,
    .GeneralProductsContainer .GeneralProductsHeadersContainer .GeneralProductsScrollListContainer{
       
    }*/
    .GeneralProductsContainer .GeneralProductsHeadersContainer .GeneralProductsSuperTitle{
        width: 70%;
        margin-right: 3%;
        font-size: 1.7em;
    }
    .GeneralProductsContainer .GeneralProductsHeadersContainer .GeneralProductsScrollListContainer{
        width: 25%;
    }
    .GeneralProductsContainer .GeneralProductsHeadersContainer .GeneralProductsScrollListContainer button{
        border-radius: 50%;
        padding: 5px 10px;
        font-size: 15px;
        margin: 0 5px;
    }
    .GeneralProductsContainer .GeneralProductsHeadersContainer .GeneralProductsSeparationLine{
        margin: 2vh 0 4vh 0;
    }
    /*------------------------------------------*/
    /*------------------------------------------*/
    /*------------------------------------------*/
    /*------------------------------------------*/
    .GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem{
        margin: 1vh 60px 1vh 0;
        width: 250px;
    }
    .GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_ImageContainer{
        height: 30vh;
    }
    .GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_ImageContainer .GeneralProductsListItem_NewMesage{
        padding: 10px 25px;
        font-size: 1em;
    }
    .GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_ImageContainer .GeneralProductsListItem_DiscountMesage{
        left: 152px;
        padding: 7px 14px;
        font-size: 0.8em;
    }
    .GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_ImageContainer .GeneralProductsListItem_Image{
        height: 27vh;
        max-width: 100% !important;
    }
    .GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_TextContainer{
       margin-top: 1vh; 
    }
    .GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_TextContainer .GeneralProductsListItem_Name{
        font-size: 1.4em;
        margin-bottom: 1px;
    }
    .GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_TextContainer .GeneralProductsListItem_SubName{
        font-size: 1em;
    }
    .GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_TextContainer .GeneralProductsListItem_PriceContainer{
        margin-top: 3px;
    }
    .GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_TextContainer .GeneralProductsListItem_DiscountPrice{
        margin-right: 10px;
        font-size: 1.2em;
    }
    .GeneralProductsContainer .GeneralProductsList .GeneralProductsListItem .GeneralProductsListItem_TextContainer .GeneralProductsListItem_Price{
        font-size: 1.4em;
    }
}
